<template>
  <vuestic-widget :loading="isLoadingForm">
    <template slot="header">
      <div class="w-100 d-flex justify-content-between align-items-center">
        <span class="title">Update Form</span>
        <router-link class="btn btn-primary btn-sm" :to="{ name: 'business.lead-forms.index' }">
          <span>Back To List <i class="fa fa-arrow-left"></i></span>
        </router-link>
      </div>
    </template>

    <!-- language selection start -->
    <div v-if="!isLoadingForm && form" class="d-block">
      <div class="row select-div">
        <div class="col-md-6 text-left" style="max-width: 500px">
          <div class="row">
            <div class="col-md-12">
              <label class="field-label">Language</label>
              <multiselect v-model="selectedMainLanguage" :options="mainLanguageList" :multiple="false"
                :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="" label="label"
                track-by="label" :preselect-first="true" :show-labels="false" :allowEmpty="false"
                @input="onSelectLanguage">
              </multiselect>
            </div>

            <button class="btn btn-primary m-4" type="button" @click="openModalLanguage">
              <span>Add Language</span>
            </button>

            <button v-if="selectedMainLanguage.id != 'en'" class="btn btn-danger m-4" type="button"
              @click="removeLanguage">
              <span>Delete Language</span>
            </button>
          </div>
        </div>
      </div>
      <!-- language selection end -->

      <ValidationObserver ref="createForm">
        <form class="createForm" method="post" action="javascript:void(0)">
          <div class="row">
            <div class="col-6">
              <div>
                <text-input name="name" v-model="form.name" label="Name" validate="required" />
              </div>

              <!-- perview -->
              <div class="preview-box">
                <div class="preview-container">
                  <QuestionForm ref="widget" :languages="form.properties.languageList[mainLanguageIndex].value
                    " :mainLanguageIndex="mainLanguageIndex" :form="form" />
                </div>
              </div>
              <div>
                <FormQuestionStepCreateStep ref="questionStep" :form="form" :mainLanguageIndex="mainLanguageIndex"
                  @done="(form) => $emit('form-changed', form)" />
              </div>
            </div>

            <div class="col-6">
              <div class="design-box">
                <div role="tablist" class="custom-accordion ml-4">
                  <b-card v-for="section in sections" :key="section.key" no-body>
                    <b-card-header header-tag="header" role="tab" class="accordion-header">
                      <a href="javascript: void(0);" class="text-black header-link" @click="onTabClick(section.key)"
                        :class="{ active: selectedTab == section.key }">
                        <h5 class="m-0 font-size-14 d-flex justify-content-between">
                          {{ section.label }}
                          <i v-if="selectedTab !== section.key" class="fa fa-chevron-down"></i>
                        </h5>
                      </a>
                    </b-card-header>
                    <b-collapse accordion="accordion" role="tabpanel" :visible="selectedTab === section.key">
                      <b-card-body>
                        <component :languages="form.properties.languageList[mainLanguageIndex].value[
                          section.key
                          ]
                          " :is="section.component" :section="form.properties[section.key]" :type="form.type_str" />
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
              </div>
            </div>
          </div>

          <div class="col-6">
              <label class="input-label">Enable Multi Select</label>
            <vuestic-switch class="mb-3" v-model="form.is_allow_multi_select" style="max-width: 300px;" :offcolor=true>
              <span slot="trueTitle">On</span>
              <span slot="falseTitle">Off</span>
            </vuestic-switch>
            <button class="btn btn-primary" @click="validateStep">Update</button>
          </div>
          
        </form>
      </ValidationObserver>
    </div>
    <vuestic-modal :isOpen="isOpenModalLanguage" @cancel="closeModalLanguage" :cancelShown="false" :okShown="false">
      <span slot="title">Add Language</span>
      <div class="row">
        <div class="col-md-12">
          <h6 class="mb-3">You can select language here</h6>
          <label class="field-label">Language</label>
          <multiselect class="filter-select d-inline-block" v-model="selectedLanguage" :options="allLanguagesList"
            :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Language"
            label="label" track-by="id" :preselect-first="true" :show-labels="false" :searchable="true"
            :allowEmpty="false">
          </multiselect>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-12 d-flex justify-content-center">
          <button @click="addInstallLanguage" class="btn btn-primary" style="min-width: 80px">
            <span>Add</span>
          </button>
          <button type="button" @click="closeModalLanguage" class="btn btn-outline ml-2">
            Close
          </button>
        </div>
      </div>
    </vuestic-modal>
  </vuestic-widget>
</template>

<script>
import QuestionForm from "./QuestionForm";
import FormQuestionStepCreateStep from "./Components/FormQuestionStepCreateStep.vue";
import { languageList } from "../../../constants/language";
const merge = require("deepmerge");
import TriggerButtonSection from "./Components/TriggerButtonSection.vue";
import StylingSection from "./Components/StylingSection";
import QuestionTextSection from "./Components/QuestionTextSection.vue";
import QuestionOptionSection from "./Components/QuestionOptionSection";
import Button from "../../common/Button.vue";

export default {
  components: {
    QuestionForm,
    FormQuestionStepCreateStep,
    TriggerButtonSection,
    StylingSection,
    QuestionTextSection,
    QuestionOptionSection,
    Button,
  },
  data() {
    return {
      form: null,
      isLoadingForm: true,
      allLanguagesList: languageList,
      isOpenModalLanguage: false,
      selectedLanguage: "",
      mainLanguageList: [],
      mainLanguageIndex: 0,
      selectedMainLanguage: { id: "en", label: "English" },
      sections: [
        {
          key: "styling",
          label: "Styling",
          component: StylingSection,
        },
        {
          key: "triggerButton",
          label: "Buttons",
          component: TriggerButtonSection,
        },
        {
          key: "question",
          label: "Questions",
          component: QuestionTextSection,
        },
        {
          key: "questionOptions",
          label: "Options",
          component: QuestionOptionSection,
        },
      ],
      selectedTab: null,
    };
  },
  mounted() {
    this.loadForm();
  },
  methods: {
    async validateStep() {
      let stepValid = false;

      await this.$refs.createForm.validate().then(async (isValid) => {
        if (isValid) {
          stepValid = await this.update();
        }
      });

      return;
    },

    async update() {
      this.$emit("loading", true);
      await this.$store
        .dispatch("leadForm/updateLeadForm", this.form)
        .then((form) => {
          if (!this.form.id) {
            this.$router.push({
              name: "business.embedded-forms.update",
              params: { id: form.id },
            });
          }

          this.$emit("done", form);
          this.$emit("loading", false);
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.createForm.setErrors(err.response.data.errors);
          }
          this.$emit("loading", false);
        });

      return;
    },

    removeLanguage() {
      let selectedKey = this.selectedMainLanguage.id;
      this.selectedMainLanguage = { id: "en", label: "English" };
      this.mainLanguageIndex = this.form.properties.languageList.findIndex(
        (obj) => obj.language == this.selectedMainLanguage.id
      );
      let removeIndexMainLanguage = this.mainLanguageList.findIndex(
        (obj) => obj.id == selectedKey
      );
      this.mainLanguageList.splice(removeIndexMainLanguage, 1);
      let removeIndexInstallProperty =
        this.form.properties.languageList.findIndex(
          (obj) => obj.language == selectedKey
        );
      this.form.properties.languageList.splice(removeIndexInstallProperty, 1);
      this.showToast("Selected language is removed form the list");
    },
    onSelectLanguage(value) {
      this.mainLanguageIndex = this.form.properties.languageList.findIndex(
        (obj) => obj.language == value.id
      );
    },
    addInstallLanguage() {
      let findIndex = this.form.properties.languageList.findIndex(
        (obj) => obj.language == this.selectedLanguage.id
      );
      if (findIndex == -1) {
        let englishLanguageIndex = this.form.properties.languageList.findIndex(
          (obj) => obj.language === "en"
        );
        let newItem = {
          language: this.selectedLanguage.id,
          value: JSON.parse(
            JSON.stringify(
              this.form.properties.languageList[englishLanguageIndex].value
            )
          ),
        };
        this.form.properties.languageList.push(newItem);
        let questionEnglishLanguageIndex = this.form.questions.findIndex(
          (obj) => obj.language === "en"
        );
        let questionNewItem = {
          language: this.selectedLanguage.id,
          steps: JSON.parse(
            JSON.stringify(
              this.form.questions[questionEnglishLanguageIndex].steps
            )
          ),
        };
        this.form.questions.push(questionNewItem);
        this.mainLanguageList.push(this.selectedLanguage);
      }
      this.isOpenModalLanguage = false;
    },
    openModalLanguage() {
      this.isOpenModalLanguage = true;
    },
    closeModalLanguage() {
      this.isOpenModalLanguage = false;
    },
    loadForm() {
      this.isLoadingForm = true;
      this.$store
        .dispatch("leadForm/findLeadForm", {
          id: this.$route.params.id,
        })
        .then((form) => {
          this.form = form;
          this.selectedMainLanguage = languageList.find(
            (language) => language.id == "en"
          );

          this.selectedMainLanguage = languageList.find(
            (language) => language.id == "en"
          );
          this.mainLanguageList = this.form.properties.languageList.map((row) =>
            languageList.find((language) => language.id === row.language)
          );
          this.mainLanguageIndex = this.form.properties.languageList.findIndex(
            (obj) => obj.language === this.selectedMainLanguage.id
          );

          this.isLoadingForm = false;
        })
        .catch((err) => {
          this.isLoadingForm = false;
          // this.$router.push({ name: 'business.embedded-forms.index', params: { install: this.$route.params.install } })
        });
    },
    onFormChanged(form) {
      this.form = form;
    },
    onTabClick(section) {
      this.selectedTab = section;
    },
  },
};
</script>

<style scoped lang="scss">
.select-div {
  justify-content: center;
}

.design-box {
  .card {
    border-radius: 0px;

    .card-header {
      padding: 0;

      .header-link {
        padding: 20px 20px;
        display: block;

        &.active {
          background-color: #1b76d2;
          color: #fff;
        }

        &:hover {
          text-decoration: none;
        }
      }
    }

    .card-body {
      margin-top: 18px;
    }
  }
}
</style>
